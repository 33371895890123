@font-face {
  font-family: 'BB Manual Mono Pro TX';
  src: url('./assets/fonts/BBManualMonoProTX-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BB Manual Mono Pro TX';
  src: url('./assets/fonts/BBManualMonoProTX-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'BB Manual Mono Pro TX', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  margin: 0 auto;
  overflow-x: hidden;
  color: #fff;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root::-webkit-scrollbar {
  display: none;
}

.legal-drawer {
  background-color: black;
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 4.5rem;
  height: auto;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  padding: 0 2.5rem;
  font-size: 0.75rem;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .legal-drawer {
    bottom: 4rem;
  }
}

.MuiBackdrop-root {
  background: transparent !important;
}
