@import './../../styles/media-query.scss';

.modal-wrapper {
    &[data-modal-opened="false"] {
        display: none;
    }

    &[data-modal-opened="true"] {
        display: block;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    text-transform: uppercase;

    .container {
        width: 100vw;
        padding: 2.5rem;
        box-sizing: border-box;

        .header-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            box-sizing: border-box;
            padding: 2rem;
            height: 8rem;
            background: #000;

            @include mq(medium, "max") {
                // height: auto;
                padding: 0 2rem;
            }
        }

        .content-tab-buttons {
            position: relative;
            top: 0rem;
            text-align: center;
            height: 12rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            z-index: 999;
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
            background: linear-gradient(180deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

            .link-icons {
                // @include mq(medium, "max") {
                //     display: none;
                // }

                display: flex;
                justify-content: center;
                background: #000;
                z-index: 99999;

                a {
                    padding: 0 .5rem;
                }

                img {
                    width: 1.75rem;
                    height: 1.75rem;
                }
            }

            .buttons {
                display: flex;
                justify-content: center;
                background: #000;
                background: linear-gradient(180deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
                z-index: 1;

                p {
                    padding-top: 1rem;
                    margin: 0 .25rem;
                    cursor: pointer;

                    &[data-is-active="true"] {
                        border-bottom: 1px solid #fff;
                    }
                }
            }

            h3 {
                font-size: 1.5rem;

                a {
                    text-decoration: none;
                    color: #fff;
                    border-bottom: 1px solid #fff;
                }
            }

            @include mq(medium, "max") {
                padding-top: 1rem;
            }
        }

        .content-tabs {
            position: relative;
            top: 10rem;
            text-align: center;
            z-index: -1;

            [data-display-tab="true"] {
                display: block;
            }

            [data-display-tab="false"] {
                display: none;
            }

            @include mq(medium, "max") {
                top: 7rem;
            }
        }

        &[data-full-credits="true"] {
            padding:0;

            .content-tabs {
                padding: 0 5rem;

                @include mq(medium,"max") {
                    padding: 0
                }
            }
        }
    }
}

.lyrics-container {
    pre {
        display: flex;
        font-weight: 500;
        font-family: 'BB Manual Mono Pro TX', sans-serif; 
        text-align: center;
        justify-content: center;
        white-space: pre-line;
        font-size: 1.75rem;
        line-height: 4rem;
        padding-bottom: 30rem;

        @include mq(medium, "max") {
            font-size: 1.25rem;
            line-height: 3.5rem;
        }
    }
}

.credits-container {
    padding-bottom: 15rem;
    p {
        padding: 0 5rem;
        font-size: 1.25rem;
        line-height: 2rem;

        @include mq(medium, "max") {
            padding: 0 0rem;
        }
    }
}

.modal-footer {
    .close-logo {
        height: 2rem;
        filter: invert(1);
        position: fixed;
        bottom: 5rem;
        cursor: pointer;
    }

    position: fixed;
    bottom: 0rem;
    height: 10rem;
    left: 0;
    background-color: #000;
    display: flex;
    justify-content: center;
    width: 100vw;
    padding: 1rem 0;
    box-sizing: border-box;
    background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 45%, rgba(0,0,0,1) 65%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 45%, rgba(0,0,0,1) 65%, rgba(0,0,0,0) 100%);
    background: linear-gradient(0deg, rgba(0,0,0,1) 45%, rgba(0,0,0,1) 65%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

    @include mq(medium, "max") {
        padding: 2rem 2rem 1rem;
        height: 12rem;

        .close-logo {
            bottom: 6rem;
        }
    }
}


// .close-logo {
//     @include mq(medium, "max") {
//         position: fixed;
//         width: 1rem;
//         filter: invert(1);
//         left: 49vw;
//         bottom: 4rem;
//         z-index: 99999;
//     }
// }

[data-modal-opened="true"] {
    .full-credits-button {
        display: none;
    }
}

.full-credits-container {
    padding: 0 5rem 20rem;

    @include mq(medium, "max") {
        padding: 0 1rem 20rem;
    }
}

[data-title="true"] {
    text-transform: none;
    font-size: 3rem;
    margin-top:0
}

[data-bey="true"] {
    font-size: 2rem;
}

[data-crew="true"] {
    font-size: 1.6rem;
}

[data-song-title="true"] {
    font-size: 1.6rem;
    font-weight: 700;
}

[data-full-credits="true"] {
    .modal-footer {
        bottom: 0rem;

        img {
            bottom: 3rem;
        }
    }
}