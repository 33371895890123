.header-container {
    z-index: 9;
    width: 100%;
    position: fixed;
}

.header {
    width: 100%; // Ensures the header takes the full width of the screen
    display: flex;
    justify-content: center; // Centers the navigation items
    align-items: center; // Vertically centers items if necessary
    position: relative;

    @media only screen and (max-device-height: 480px) and (orientation: landscape) {
        width: 47vw;
    }

    @media screen and (max-width: 768px) {
        .chakra-icon-button {
            margin-right: 1rem; 
            z-index: 11;
            color: white !important; /* Ensure the icon is fully white */
            border: none !important; /* Remove the stroke */
            background-color: transparent !important; /* Remove the grey background */
        }

        .chakra-icon-button svg {
            fill: white !important; /* Ensure the hamburger icon is white */
            stroke: none !important; /* Remove any stroke */
        }
    }
}

.hamburger-icon {
    color: white !important; /* Ensure the hamburger icon is fully white */
    border: none !important; /* Remove any borders or strokes */
    background-color: transparent !important; /* Remove the grey background */
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 12;
}

.modal {
    position: fixed;
    top: 0;
    right: 0; /* Aligns the modal to the right */
    width: 75vw;
    height: 100vh;
    background-color: black;
    z-index: 11; /* Ensure it overlays all other content, including act ii COWBOY CARTER */
    display: none;

    &.open {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.close-icon {
    color: white !important;
    font-size: 1.5rem;
    position: absolute; /* Positioning the close button to the top right */
    top: 1rem; /* Same top margin as the hamburger icon */
    right: 1rem; /* Same right margin as the hamburger icon */
    z-index: 13; /* Ensure it’s above the modal content */
}

.menu-item {
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    &:hover {
        color: #ccc;
    }
}

.footer-item {
    font-size: 0.9rem; /* Smaller text size for footer items */
    text-transform: uppercase;
    margin: 0 0.5rem; /* Adjust spacing to fit neatly */
    text-align: center;
    cursor: pointer;
    &:hover {
        color: #ccc;
    }
}