@import './../../styles/media-query.scss';

.song-wrapper {
    margin-bottom: 1.5rem;
    cursor: pointer;

    &[data-is-expanded="false"] {
        font-size: 1rem;
    }

    &[data-is-expanded="true"] {
        font-size: 1.4rem;
        a {
            padding: 0 0.5rem;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;

        .track-number {
            display: flex;

            .fixed-track-number {
                // position: absolute;
                // left: 2.5rem;
            }

            .logo-list {
                @include mq(medium, "max") {
                    width: 2rem;
                }

                @include mq(medium, "max") {
                    text-align: center;
                }

                .dsp-links {
                    text-align: center;

                    a img {
                        padding: 5px 0;

                        &:first-of-type {
                            padding-bottom: 8px;
                        }
                    }
                }

                // Scoped styles for song module icons
                .app-link {
                    .link-logo {
                        width: 1.75rem;
                        /* height: 1.75rem; */
                        margin: 0; // Ensure no additional margin
                        display: inline-block; // Ensures proper alignment
                        vertical-align: middle; // Aligns with text/baseline
                    }
                }

                .lyrics-and-credits-buttons {
                    display: flex;
                    justify-content: flex-end;

                    @include mq(medium, "min") {
                        position: relative;
                    }

                    p {
                        margin-top: 0;
                        font-size: 1rem;
                        text-transform: uppercase;
                        padding: 0.25rem;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    .track-title {
        text-align: right;
        text-transform: uppercase;
        padding-left: 2rem;

        .lyrics-and-credits-buttons {
            display: flex;
            justify-content: flex-end;

            p {
                margin-top: 0;
                font-size: 1rem;
                text-transform: uppercase;
                padding: 0.25rem;
            }
        }
    }
}