@import './../../styles/media-query.scss';

.content {
    width: 100vw;
    height: auto;
    padding: 2.5rem;
    box-sizing: border-box;
    position: absolute;
    // height: calc(100vh - 13rem);
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: -1;
    top: 7rem;
    padding-bottom: 10rem;
    z-index: -1;

    &::-webkit-scrollbar {
        display: none;
      }

    @include mq(medium, "max") {
      &[data-modal-opened="true"] {
        top: 0rem;
      }
      
    }
}