.desktop-footer {
    padding: 1.5rem 7rem;
    bottom: 1.5rem; // Restores the bottom spacing to 1.5rem as in production
    background: transparent; // Ensure the background is transparent as in production

    @media screen and (max-width: 768px) {
        display: none; // Hide desktop footer on mobile devices
    }
}

#mobile-footer {
    padding-top: 1.25rem;
    background: #000;
    z-index: 1;

    @media screen and (min-width: 769px) {
        display: none; // Hide mobile footer on desktop devices
    }
}

/* Styles for the modal displayed on mobile when the hamburger menu is clicked */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 75vw;
    height: 100vh;
    background-color: black;
    z-index: 10;
    display: none;

    &.open {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;

    a {
        color: white;
        font-size: 20px;
        text-transform: uppercase;
        padding: 1.5rem;
        text-decoration: none;

        &:hover {
            color: #ccc;
        }
    }
}

.mobile-footer {
    z-index: 1;

    @media screen and (min-width: 769px) {
        display: none; // Ensure mobile footer doesn't display on larger screens
    }
}
