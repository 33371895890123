$queryPoints: (
  small: 768px,
  medium: 1024px,
);

@mixin mq($screenSize, $sizeLimit: min, $deviceOrientation: width) {
  @if map_has_key($queryPoints, $screenSize) {
    $screenSize: map_get($queryPoints, $screenSize);
  }

  @if $sizeLimit == max {
    $screenSize: $screenSize - 1px;
  }

  @if $deviceOrientation == width {
    @media screen and (#{$sizeLimit}-width: $screenSize) {
      @content;
    }
  }

  @if $deviceOrientation == height {
    @media screen and (#{$sizeLimit}-height: $screenSize) {
      @content;
    }
  }
}